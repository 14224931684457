import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = []

  connect() {
    if (this.shouldShowModal()) {
      this.autoClickButton()
    }
  }

  shouldShowModal() {
    const status = document.getElementById('openPolicyModal')?.dataset.status
    return status === "true"
  }

  autoClickButton() {
    const button = document.getElementById('openPolicyModal')
    if (button) {
      button.click()
    }
  }

  handleAgreeAndContinue() {
    const url = '/api/v1/user/confirm_policy'
    const params = { policy_agreement_status: 'agreed' }
    this.updateStatusUserPolicy(url, params)
  }

  handleContinueWithoutAcknowledgement() {
    const url = '/api/v1/user/confirm_policy'
    const params = { policy_agreement_status: 'skipped' }
    this.updateStatusUserPolicy(url, params)
  }

  updateStatusUserPolicy(url, params) {
    try {
      axios.post(url, params).then((response) => {
        console.log('Policy confirmation successfully')
      });
    } catch (e) {
      console.log(e);
    }
  }
}
